@import 'colors';
@import 'mixin';

:root {
  --primary: #{$violet};
  --primary-rgb: #{$violet-rgb};
}

[data-theme='Cyan'] {
  --primary: #{$cyan};
  --primary-rgb: #{$cyan-rgb};
}

[data-theme='Green'] {
  --primary: #{$green};
  --primary-rgb: #{$green-rgb};
}
