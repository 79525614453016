@import 'colors';
@import 'mixin';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: $background;
  color: $text-color;
}

body,
div,
input,
select,
textarea,
a,
button,
pre {
  font-size: 14px;
  font-family: 'Jost', sans-serif;
  vertical-align: top;
  font-weight: 300;
  color: var(--text-color);
  border: 0;
  outline: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
textarea {
  resize: none;
}

a,
button {
  @extend .trstn;
  &:hover {
    @extend .trstn;
  }
}

::selection {
  background-color: $background;
  color: $white;
}

::-moz-selection {
  background-color: $background;
  color: $white;
}

button {
  cursor: pointer;
  background: transparent;
}

:disabled {
  cursor: not-allowed;
  @include opacity(0.5);
}

label {
  @extend .display-flex;
  @extend .flex-direction-column;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  width: 100%;
  // color: var(--primary);
  input,
  textarea,
  select,
  ul {
    margin-top: 5px;
  }
  &:not(:first-of-type) {
    padding-top: 20px;
  }
  &.npt {
    padding-top: 0;
  }
}

input,
textarea,
select {
  // background: var(--border);
  background: transparent;
  padding: 10px;
  color: var(--text-color);
  @include border-radius($border-radius);
  border: 1px solid $bkg-3;
  @extend .bsbb;
  height: 42px;
  width: 100%;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--text-color);
  opacity: 0.5; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--text-color);
  opacity: 0.5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--text-color);
  opacity: 0.5;
}

::-webkit-scrollbar {
  display: none;
}

textarea {
  min-height: 250px;
}

button {
  padding: 0;
}

h1 {
  font-size: 30px;
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-center-start;
}

h2 {
  font-size: 20px;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-weight: 600;

  &.mt {
    margin-top: 20px;
  }
}

pre {
  font-family: 'Source Code Pro', monospace;
}

/* Scrollbars */

.element::-webkit-scrollbar {
  width: 0 !important;
}
.element {
  overflow: -moz-scrollbars-none;
}
.element {
  -ms-overflow-style: none;
}
