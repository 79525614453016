@import './styles/colors';
@import './styles/mixin';

.Bkg {
  background: url(./assets/website_bkg.svg) no-repeat center center fixed;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.Top {
  background: url(./assets/website_top.svg) no-repeat center center fixed;
  background-size: cover;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Bottom {
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: url(./assets/website_bottom.svg) no-repeat center center fixed;
  background-size: cover;
}

.Logo {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @extend .display-flex;
  @extend .flex-center;
  img {
    max-width: 50%;
  }
}
