@import 'colors';
@import 'mixin';

.page-title {
  font-size: 30px;
  font-weight: 600;
  color: $text-color;
  @extend .bsbb;
  width: 100%;
  text-shadow: 0px 0px 10px rgba(var(--primary-rgb), 0.5);
  height: 30px;
  margin-bottom: 20px;
}

.backdrop {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.span-label {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  width: 100%;
  color: var(--primary);
  margin-bottom: 5px;
  display: block;
}

.page-content {
  @extend .bsbb;
  padding: 20px;
}

.card {
  background: rgba($bkg-3, 0.5);
  padding: 20px;
  @include border-radius($border-radius);
  @extend .bsbb;
  @extend .backdrop;
}

.card-title {
  text-shadow: 0px 0px 10px rgba(var(--primary-rgb), 0.5);
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}

.ul-page-divider {
  @extend .display-flex;
  @extend .flex-direction-row;
  width: 100%;
  > li {
    width: 100%;
    &:first-of-type {
      margin-right: 10px;
    }
    &:last-of-type {
      margin-left: 10px;
    }
  }
}
